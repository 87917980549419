// body{
//     background-color: black!important;
// }


.ossium-link {
    color: #dc4e33;
    text-decoration: none;
    border: 2px solid #dc4e33;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}

.ossium-link:hover{
        color: #f77d65;
        border: 2px solid green;

}