// Variables
$enable-rounded: true !default;
$enable-rfs: true !default;
$enable-antialiasing: true !default;
$enable-negative-margins: true !default;

$border-width: 1px !default;
$border-color: #EAEDF0 !default;
$border-radius: 0.5rem !default;
$border-radius-sm: 0.375rem !default;
$border-radius-lg: 0.75rem !default;

$min-contrast-ratio: 2 !default;

$body-color: #030712 !default;
$text-muted: #495460 !default;
$headings-color: #171A1F !default;

$link-decoration: none !default;
$link-hover-decoration: none !default;

$white: #fff !default;
$black: #05070E !default;

$gray-100: #F3F7FA !default;
$gray-200: #EAEDF0 !default;
$gray-300: #CBD2D9 !default;
$gray-400: #B8C1CC !default;
$gray-500: #7F8995 !default;
$gray-600: #495460 !default;
$gray-700: #171A1F !default;
$gray-800: #111827 !default;
$gray-900: #030712 !default;

$primary: #5E27FF !default;
$secondary: #EAEDF0 !default;
$success: #26DA42 !default;
$info: #457EFA !default;
$warning: #FFBB29 !default;
$danger: #FF3131 !default;
$light: #F3F7FA !default;
$dark: #070B18 !default;

$primary-light: #eef2ff !default;
$secondary-light: #F3F7FA !default;
$success-light: #f0fdf4 !default;
$info-light: #eff6ff !default;
$warning-light: #fffbeb !default;
$danger-light: #fef2f2 !default;
$light-light: #FFFFFF !default;
$dark-light: #171A1F !default;
$primary-dark: #4f46e5 !default;
$secondary-dark: #7F8995 !default;
$success-dark: #16a34a !default;
$info-dark: #2563eb !default;
$warning-dark: #ca8a04 !default;
$danger-dark: #dc2626 !default;
$light-dark: #CBD2D9 !default;

$theme-colors: (
    primary: $primary,
    secondary: $secondary,
    success: $success,
    info: $info,
    warning: $warning,
    danger: $danger,
    light: $light,
    dark: $dark,
    primary-light: $primary-light,
    secondary-light: $secondary-light,
    success-light: $success-light,
    info-light: $info-light,
    warning-light: $warning-light,
    danger-light: $danger-light,
    light-light: $light-light,
    primary-dark: $primary-dark,
    secondary-dark: $secondary-dark,
    success-dark: $success-dark,
    info-dark: $info-dark,
    warning-dark: $warning-dark,
    danger-dark: $danger-dark,
    light-dark: $light-dark,
    dark-light: $dark-light
) !default;

$blue: #457EFA !default;
$red: #FF3131 !default;
$yellow: #FFBB29 !default;
$green: #26DA42 !default;

$blue-100: #dbeafe !default;
$blue-200: #bfdbfe !default;
$blue-300: #93c5fd !default;
$blue-400: #60a5fa !default;
$blue-500: #457EFA !default;
$blue-600: #2563eb !default;
$blue-700: #1d4ed8 !default;
$blue-800: #1e40af !default;
$blue-900: #dbeafe !default;

$indigo-100: #e0e7ff !default;
$indigo-200: #c7d2fe !default;
$indigo-300: #a5b4fc !default;
$indigo-400: #818cf8 !default;
$indigo-500: #5E27FF !default;
$indigo-600: #4f46e5 !default;
$indigo-700: #4338ca !default;
$indigo-800: #3730a3 !default;
$indigo-900: #312e81 !default;

$purple-100: #ede9fe !default;
$purple-200: #ddd6fe !default;
$purple-300: #c4b5fd !default;
$purple-400: #a78bfa !default;
$purple-500: #8b5cf6 !default;
$purple-600: #7c3aed !default;
$purple-700: #6d28d9 !default;
$purple-800: #5b21b6 !default;
$purple-900: #4c1d95 !default;

$pink-100: #fce7f3 !default;
$pink-200: #fbcfe8 !default;
$pink-300: #f9a8d4 !default;
$pink-400: #f472b6 !default;
$pink-500: #ec4899 !default;
$pink-600: #db2777 !default;
$pink-700: #be185d !default;
$pink-800: #9d174d !default;
$pink-900: #831843 !default;

$red-100: #fee2e2 !default;
$red-200: #fecaca !default;
$red-300: #fca5a5 !default;
$red-400: #FC5E3B !default;
$red-500: #FF3131 !default;
$red-600: #dc2626 !default;
$red-700: #b91c1c !default;
$red-800: #991b1b !default;
$red-900: #7f1d1d !default;

$yellow-100: #fef3c7 !default;
$yellow-200: #fde68a !default;
$yellow-300: #fcd34d !default;
$yellow-400: #fbbf24 !default;
$yellow-500: #FFBB29 !default;
$yellow-600: #ca8a04 !default;
$yellow-700: #a16207 !default;
$yellow-800: #854d0e !default;
$yellow-900: #713f12 !default;

$green-100: #dcfce7 !default;
$green-200: #bbf7d0 !default;
$green-300: #86efac !default;
$green-400: #4ade80 !default;
$green-500: #26DA42 !default;
$green-600: #16a34a !default;
$green-700: #15803d !default;
$green-800: #166534 !default;
$green-900: #14532d !default;

$container-max-widths: (
    sm: 640px,
    md: 768px,
    lg: 1024px,
    xl: 1280px
) !default;

$font-family-base: "Inter", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-size-base: 1rem;
$font-size-sm: 0.875rem;
$font-size-lg: 1.125rem;
$font-weight-bold: 700 !default;
$font-weight-medium: 500 !default;
$small-font-size: 0.875rem !default;

$line-height-lg: 2rem !default;
$headings-font-family: "Sentient", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$headings-font-weight: 400 !default;

$body-line-height: 1 !default;
$headings-line-height: 1.125 !default;

$h1-font-size: 4rem !default;
$h2-font-size: 3rem !default;
$h3-font-size: 2.5rem !default;
$h4-font-size: 1.313rem !default;
$h5-font-size: 1rem !default;
$h6-font-size: 0.875rem !default;

$display-font-weight: 700 !default;
$display-font-sizes: (
    1: 4rem,
    2: 3rem,
    3: 2.5rem,
    4: 1.75rem,
    5: 1.5rem
) !default;

$display-line-height: 1.125 !default;
$lead-font-size: 1.25rem !default;
$lead-font-weight: 400 !default;

$box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !default;
$box-shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !default;
$box-shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !default;
$box-shadow-inset: inset 0px 4px 4px rgba(0, 0, 0, 0.06) !default;

$spacers: (
    0: 0px,
    1: 0.25rem,
    2: 0.5rem,
    3: 0.75rem,
    4: 1rem,
    5: 1.25rem,
    6: 1.5rem,
    7: 1.75rem,
    8: 2rem,
    9: 2.25rem,
    10: 2.5rem,
    11: 2.75rem,
    12: 3rem,
    14: 3.5rem,
    16: 4rem,
    20: 5rem,
    24: 6rem,
    28: 7rem,
    32: 8rem,
    36: 9rem,
    40: 10rem,
    44: 11rem,
    48: 12rem,
    52: 13rem,
    56: 14rem,
    60: 15rem,
    64: 16rem,
    72: 18rem,
    80: 20rem,
    96: 24rem
) !default;

$negative-spacers: if($enable-negative-margins, negativify-map($spacers), null) !default;

$badge-font-size: 0.875rem !default;
$badge-font-weight: 600 !default;
$badge-color: #030712 !default;
$badge-padding-y: 0.25rem !default;
$badge-padding-x: 0.75rem !default;
$badge-border-radius: 9999px !default;

$input-btn-padding-y: 1rem !default;
$input-btn-padding-x: 1.25rem !default;
$input-btn-font-size: 1rem !default;
$input-btn-line-height: 1.5 !default;

$input-btn-padding-y-sm: 0.5rem !default;
$input-btn-padding-x-sm: 1rem !default;
$input-btn-font-size-sm: 0.75rem !default;
$input-btn-padding-y-lg: 1.25rem !default;
$input-btn-padding-x-lg: 2rem !default;
$input-btn-font-size-lg: 1.125rem !default;

$btn-color: #5E27FF !default;
$btn-font-weight: 600 !default;

$input-border-color: #B8C1CC !default;
$input-placeholder-color: #7F8995 !default;
$input-font-size: 1rem !default;
$input-padding-y: 1.125rem !default;
$input-padding-x: 1rem !default;

$nav-link-font-size: 1rem !default;
$nav-link-font-weight: 500 !default;

$navbar-padding-y: 1.25rem !default;
$navbar-padding-x: 1rem !default;
$navbar-nav-link-padding-x: 1.5rem !default;
$navbar-light-color: #171A1F !default;
$navbar-light-hover-color: #495460 !default;
$navbar-light-active-color: #5E27FF !default;
$navbar-light-disabled-color: #B8C1CC !default;
$navbar-dark-color: #fff !default;
$navbar-dark-hover-color: #EAEDF0 !default;
$navbar-dark-active-color: #fff !default;
$navbar-dark-disabled-color: #B8C1CC !default;

$alert-color: null !default;
$alert-font-size: null !default;
$alert-border-width: $border-width !default;
$alert-border-radius: $border-radius !default;

$alert-border-top-width: $alert-border-width !default;
$alert-border-right-width: $alert-border-width !default;
$alert-border-bottom-width: $alert-border-width !default;
$alert-border-left-width: $alert-border-width !default;

$alert-border-top-right-radius: $alert-border-radius !default;
$alert-border-top-left-radius: $alert-border-radius !default;
$alert-border-bottom-right-radius: $alert-border-radius !default;
$alert-border-bottom-left-radius: $alert-border-radius !default;

$badge-text-transform: null !default;

$display-font-family: null !default;

$font-weight-medium: null !default;
$font-weight-semibold: null !default;

$body-letter-spacing: null !default;
$headings-letter-spacing: -2px !default;
